import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useCommonApi } from '@/shared/contexts';
import { PopupCallToActionModal } from '@/features/popup-call-to-action';
import { useCalculatorStore } from '@/stores/calculator/calculator-store-provider';
import { usePopupBannerStore } from '@/stores/popup-banner/popup-banner-store-provider';
import { navigationsMap } from '@/shared/lib';

export const PopupCallToAction = () => {
  const commonApiContext = useCommonApi();

  const popupCallToActionSettings =
    commonApiContext?.layoutSettings?.attributes.features?.popupCallToAction;

  const timeoutSeconds = popupCallToActionSettings?.secondsToShow || 10;

  const router = useRouter();

  const { isFocused, on: highlightCalculator } = useCalculatorStore(
    (state) => state,
  );
  const { alreadyShowed, setAlreadyShowed } = usePopupBannerStore(
    (state) => state,
  );

  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isFocused || alreadyShowed) return;

    const timeout = setTimeout(() => {
      setOpen(true);
    }, timeoutSeconds * 1000);

    return () => clearTimeout(timeout);
  }, [isFocused]);

  const handleCloseModal = () => {
    setOpen(false);
    setAlreadyShowed(true);
  };

  const handleClickModal = () => {
    if (router.pathname === navigationsMap.home.href) {
      highlightCalculator();
    } else {
      router.push(navigationsMap.home.href).then(() => {
        highlightCalculator();
      });
    }

    handleCloseModal();
  };

  return (
    <PopupCallToActionModal
      image={popupCallToActionSettings?.image}
      isOpen={isOpen}
      handleClose={handleCloseModal}
      handleClick={handleClickModal}
    />
  );
};
