import { PropsWithChildren } from 'react';
import { Box, Stack, SxProps, Theme } from '@mui/material';
import { MainLayoutHeader } from './main-layout-header';
import { MainLayoutFooter } from './main-layout-footer';
import { Chatbot } from '@/features/chatbot';
import { PopupCallToActionOuter } from '@/features/popup-call-to-action';

export type MainLayoutProps = PropsWithChildren & {
  sx?: SxProps<Theme>;
};

export const MainLayout = ({ sx, children }: MainLayoutProps) => {
  return (
    <>
      <Stack
        sx={{
          margin: 'auto',
          height: '100dvh',
        }}
      >
        <MainLayoutHeader />

        <Box
          component="main"
          pt={{ xs: 8.75, md: 9 }}
          sx={{ flex: '1 0 auto', ...sx }}
        >
          {children}
        </Box>

        <MainLayoutFooter />
        <Chatbot />

        <PopupCallToActionOuter />
      </Stack>
    </>
  );
};
