import React from 'react';
import { useCommonApi } from '@/shared/contexts';
import { PopupCallToAction } from '@/features/popup-call-to-action';

export const PopupCallToActionOuter = () => {
  const commonApiContext = useCommonApi();

  const popupCallToActionSettings =
    commonApiContext?.layoutSettings?.attributes.features?.popupCallToAction;

  if (!popupCallToActionSettings?.enabled) {
    return null;
  }

  if (!popupCallToActionSettings?.image) {
    return null;
  }

  return <PopupCallToAction />;
};
