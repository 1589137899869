import React from 'react';
import { Box, ButtonBase, IconButton, Modal } from '@mui/material';
import Image from 'next/image';
import { PopupCallToActionFeature } from '@/shared/api/common-api';
import { IconClose } from '@/shared/ui';

type PopupCallToActionModalProps = Pick<PopupCallToActionFeature, 'image'> & {
  isOpen: boolean;
  handleClose: () => void;
  handleClick: () => void;
};

export const PopupCallToActionModal = (props: PopupCallToActionModalProps) => {
  const { image, isOpen, handleClose, handleClick } = props;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-target-offer"
      aria-describedby="target-offer-for-calculator"
      sx={{
        zIndex: 3000,
      }}
    >
      <Box
        sx={{
          maxWidth: '70vw',
          maxHeight: '90vh',
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          margin: 'auto',
          width: 'fit-content',
          outline: 'none',
        }}
      >
        <IconButton
          sx={{
            color: 'white',
            position: 'absolute',
            right: 8,
            top: 0,
            zIndex: 10,
          }}
          onClick={handleClose}
        >
          <IconClose />
        </IconButton>
        {image ? (
          <ButtonBase onClick={handleClick}>
            <Image
              src={image?.data?.attributes?.url}
              alt={image?.data?.attributes?.alternativeText || ''}
              width={image?.data?.attributes?.width}
              height={image?.data?.attributes?.height}
              style={{
                objectFit: 'contain',
                height: 'auto',
                maxHeight: '90vh',
              }}
            />
          </ButtonBase>
        ) : null}
      </Box>
    </Modal>
  );
};
